import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { SellerComponent } from './seller/seller.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SellerManageComponent } from './seller-manage/seller-manage.component';
import { SellerFeesManageComponent } from './seller-fees-manage/seller-fees-manage.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

const routes: Routes = [
  // { path: '', component: SellerComponent },
  { path: '', component: SellerComponent },
  { path: 'new/:usertype', component: SellerManageComponent },
  { path: 'edit/:usertype/:id', component: SellerManageComponent },
  { path: ':id/manage-fees' , component: SellerFeesManageComponent }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    NgSelectModule,
    ModalModule.forRoot()
  ],
  providers: [
    BsDropdownDirective
  ],
  declarations: [
    SellerComponent,
    SellerManageComponent,
    SellerFeesManageComponent,
  ],
  exports: [
    RouterModule
  ]
})

export class SellersModule { }
