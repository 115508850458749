/* src/app/routes/sellers/seller-fees-manage/seller-fees-manage.component.scss */
.select-profile .disabled img {
  opacity: 0.6;
}
.select-profile img {
  max-width: 48px;
  max-height: 30px;
  width: 100%;
}
.select-profile img.img-small {
  max-width: 38px;
  max-height: 24px;
}
/*# sourceMappingURL=seller-fees-manage.component.css.map */
