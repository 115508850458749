import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators , UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { SellerService } from '../../../services/seller.service';
import { SharedService } from '../../../shared/shared.service';
import { RouterExtService } from '../../../core/services/router.ext.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-seller-fees-manage',
  templateUrl: './seller-fees-manage.component.html',
  styleUrls: ['./seller-fees-manage.component.scss']
})
export class SellerFeesManageComponent implements OnInit {

  idMerchant : any;
  feeGeneral : any;
  sourceProfiles : any;
  selectedProfile : any = null;
  sourceConfiguration : any;

  configurationForm = this.fb.group({
    profile: [null],
    percentageFee: [null , Validators.required],
    fixedFee: [null , Validators.required]
  })

  editConfigurationForm = this.fb.group({
    configurations: this.fb.array([])
  })

  constructor(private router: Router,
    private route: ActivatedRoute,
    private logService: LoggingService,
    private location : Location,
    private sellerService: SellerService,
    private translate: TranslateService,
    private fb : UntypedFormBuilder, 
    private sharedService : SharedService,
    private routerExtService: RouterExtService
  ) { }

  ngOnInit() {
    this.idMerchant = this.route.snapshot.params['id'];
    this.loadSellerInfoBase( this.idMerchant )
    this.loadSources()
  }

  loadSellerInfoBase( merchantId ) {
    this.sellerService.getSellerDatas( merchantId ).subscribe((resp : any) => {
      // console.log( 'loadSellerInfoBase' , resp )
      this.feeGeneral = resp
    })
  }

  async loadSources() {
    await this.getConfigurationProfiles();
    await this.getConfigurationList();
  }

  async getConfigurationProfiles() {
    let resp ;
    resp = await this.sellerService.getFeeConfigurationProfiles().toPromise()
    if( resp.results ) {
      this.sourceProfiles = resp.results
      this.sourceProfiles = [...this.sourceProfiles]
    } else {
      swal.fire('Ops!' , this.translate.instant('error.error_0000') , "error")
    }
    // console.log( '...async getConfigurationProfiles()' , this.sourceProfiles)
  }

  async getConfigurationList() {
    // console.log( 'NOT async SellerFeesManageComponent()')
    let resp;
    resp = await this.sellerService.getConfigurations( this.sharedService.isAdmin() ? this.idMerchant : null ).toPromise()
    
    // console.log( 'getConfigurationList' , resp)
    if( resp.results ) {
      this.sourceConfiguration = resp.results
      this.sourceConfiguration = this.sourceConfiguration.map( x => {x.isReadOnly = true ; return x} )
      // console.log( 'after map' , this.sourceConfiguration )
      this.editConfigurationForm.removeControl('configurations')
      this.editConfigurationForm.addControl( 'configurations' , this.fb.array([]) )
      this.sourceConfiguration.forEach(element => {
        this.addNewConfiguration( element )
      });
      // console.log( 'form array Name' , this.editConfigurationForm.controls )
    } else {
      swal.fire('Ops!' , this.translate.instant('error.error_0000') , "error")
    }
  }

  onProfileSelected( event ) {
    // console.log( 'onProfileSeleted' , event )
    this.selectedProfile = event
  }

  createConfiguration() {
    // console.log( 'paramObj = this.selectedProfile' , this.selectedProfile )
    swal.showLoading();
    let paramObj = {
      description : this.selectedProfile.description + " fee",
      profile : { id : this.selectedProfile.id },
      idMerchant : this.idMerchant || null,
      percentageFee : this.configurationForm.value.percentageFee,
      fixedFee : this.configurationForm.value.fixedFee
    }
    
    this.sellerService.createFeeConfiguration( paramObj ).subscribe((resp) => {
      // console.log( 'createConfiguration resp' , resp )
      if( resp.outcome.success ) {
        swal.fire( this.translate.instant('seller.swal.success_create_configuration_title') , this.translate.instant('seller.swal.success_create_configuration_text'), "success").then((value) => {
          this.configurationForm.reset()
          this.getConfigurationList()
          this.discardSelectedProfile()
        })
      } else {
        if( resp.outcome.code == "0012") {
          swal.fire( this.translate.instant('seller.swal.error_0012_create_configuration_title') , this.translate.instant('seller.swal.error_0012_create_configuration_text') , "error")
          this.discardSelectedProfile()
        } else {
          swal.fire( this.translate.instant('seller.swal.error_create_configuration_title') , this.translate.instant('seller.swal.error_create_configuration_text') , "error")
        }
      }
    })
  }

  discardSelectedProfile() {
    this.configurationForm.reset()
  }

  get customConfiguration() : UntypedFormArray {
    return this.editConfigurationForm.get('configurations') as UntypedFormArray;
  }

  newConfiguration( element = null ) : UntypedFormGroup {
    return this.fb.group({
      percentageFee: [element != null ? element.percentageFee : '' , Validators.required],
      fixedFee: [element != null ? element.fixedFee : '' , Validators.required],
      id: [element != null ? element.id : null , Validators.required],
      description: [element != null ? element.description : '' , Validators.required],
      profile: [element != null ? element.profile : null , Validators.required],
      idMerchant: [element != null ? element.idMerchant : null , Validators.required],
      enabled: element != null ? element.enabled : true
    })
  }

  addNewConfiguration( element = null ) {
    this.customConfiguration.push( this.newConfiguration( element ) )
  }

  toggleEdit( item : UntypedFormGroup , element: any ) {
    // console.log( item )
    element.isReadOnly = !element.isReadOnly
    item.patchValue({
      percentageFee: element.percentageFee,
      fixedFee: element.fixedFee
    })
  }

  async updateConfiguration( item : UntypedFormGroup , element : any , showNotificationDialog : boolean = null) {
    let _showDialog : boolean = true;
    if( showNotificationDialog != null && showNotificationDialog == false ) {
      _showDialog = showNotificationDialog;
    }
    // console.log( 'updateConfiguration' , item.value )
    swal.showLoading();
    let resp = await this.sellerService.updateConfiguration( item.value ).toPromise()
    if( resp.outcome ) {
      // console.log( 'updateConfiguration()' , resp )
      if( _showDialog ) {
        swal.fire( this.translate.instant('seller.swal.confirm_update_title') , '' , "success")
      } else {
        swal.close()
      }
      this.getConfigurationList()
    }
    if( element != null ) {
      this.toggleEdit( item, element )
      // console.log( 'toggleEdit after update' , this.sourceConfiguration[i] )
    }
    
  }

  toggleEnable( item : UntypedFormGroup ) {
    item.controls.enabled.patchValue( !item.value.enabled )
    let paramObj = item
    this.updateConfiguration( paramObj , null , false )
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/seller');
    } else {
      this.location.back();
    }
  }

  deleteConfiguration( item ) {
    let paramObj = item.value
    swal.fire({
      icon: "warning",
      title: this.translate.instant('seller.swal.warning_delete_configuration_title'),
      text: this.translate.instant('seller.swal.warning_delete_configuration_text'),
      confirmButtonText: this.translate.instant('seller.swal.confirm_delete_text'),
      confirmButtonColor: "#ed2d2d",
      showCancelButton : true,
      cancelButtonText: this.translate.instant('general.cancel')
    }).then((result) => {
      if( result.value ) {
        swal.showLoading();
        this.sellerService.deleteConfiguration( paramObj.id ).subscribe((resp) => {
          if( resp.outcome.success ) {
            swal.fire( this.translate.instant('swal.title_Success') , this.translate.instant('swal.content_Success') , "success" ).then((value) => {
              this.getConfigurationList()
            })
          }
        })
      } else {
        swal.close()
      }
    })
  }

}
