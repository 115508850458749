<div class="container wrapper">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 *ngIf="!idMerchant" class="float-left m-bottom-0">{{ 'insert.' + typeOfUser | translate }}</h3>
      <h3 *ngIf="idMerchant" class="float-left m-bottom-0">{{ 'insert.modify-' + typeOfUser | translate }}</h3>
      <button type="button" class="btn btn-danger float-right button-style" (click)="navigationBack()">{{'insert.exit' |
        translate}}
      </button>
    </div>
  </div>
  <div class="row card">
    <div class="full-width width100">
      <div class="white-bg p-15">
        <div class="col-md-12">
          <!--<div class="row">
            <div class="col-md-6" *ngIf="typeOfUser != 'reseller'"> 
              <div class="has-float-label">
                <ng-select [clearable]="false" (change)="onChangeProfile($event)" [items]="profiles" bindValue="id" bindLabel="description"
                  [(ngModel)]="selectedProfileId" class="custom" placeholder="{{'insert.user_level' | translate}}" id="user-level"></ng-select>
              </div>
            </div>
            <div class="col-md-6 display-n" *ngIf="typeOfUser == 'reseller'"> 
              <div class="has-float-label">
                <ng-select [disabled]="true" [clearable]="false" (change)="onChangeProfile($event)" [items]="profiles" bindValue="id" bindLabel="description"
                  [(ngModel)]="selectedProfileId" class="custom" placeholder="{{'insert.user_level' | translate}}" id="user-level"></ng-select>
              </div>
            </div>
          </div>-->
          <div class="col-md-12">
            <div class="row">
              <!--<app-all-users-form *ngIf="selectedProfileId == 1" [userData]="userData"></app-all-users-form>
              <app-super-admin-form *ngIf="selectedProfileId == 1" [userData]="userData"></app-super-admin-form>
              <app-reseller-form *ngIf="selectedProfileId == 2" [userData]="userData" [merchantList]="merchantList" ></app-reseller-form>-->
              <app-merchant-form [sellerData]="sellerData" [resellers]="resellers"></app-merchant-form>
              <!---<app-responsable-form *ngIf="selectedProfileId == 4" [userData]="userData" [rates]="rates" [resellers]="resellers"></app-responsable-form>
              <app-agent-form *ngIf="selectedProfileId == 5" [userData]="userData" [rates]="rates" [resellers]="resellers"></app-agent-form>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
