import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators, UntypedFormBuilder, ControlContainer, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../../../core/services/base.service';
import { LoggingService } from '../../../core/services/log.service';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SettingService } from '../../../services/setting.service';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { SellerService } from '../../../services/seller.service';
import { DualListComponent } from 'angular-dual-listbox';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-seller-manage',
  templateUrl: './seller-manage.component.html',
  styleUrls: ['./seller-manage.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SellerManageComponent implements OnInit {
  merchantList: Array<any>;
  userData: any;
  profile: any;
  username: any;
  selectedProfileId: any;
  selectedProfile: any;
  userProfile: any = [];
  profilesDatas: any = [];
  idMerchant: any;
  user: any;
  profiles: any;
  resellers: any;
  levels: any;
  agentLevels: any;
  rates: any;
  idReseller: any;
  private stations: any = [];
  private agents: any = [];
  tab = 1;
  keepSorted = true;
  key: string;
  keyId: string;
  display: any;
  filter = false;
  source: Array<any>;
  confirmed: Array<any>;
  userAdd = '';
  disabled = false;
  sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;
  private users: Array<any>;
  private sourceAgents: Array<any>;
  private confirmedAgents: Array<any>;
  originalUserDatas: {};
  typeOfUser: any;
  pageType: any;
  sellerData: any;

  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private userService: UserService,
    private sellerService: SellerService,
    private route: ActivatedRoute,
    private basic: AppService,
    private logService: LoggingService,
    private routerExtService: RouterExtService
  ) {
    this.getLevel();
    //this.getRateList();
  }

  ngOnInit() {
    this.idReseller = localStorage.getItem("idReseller");
    this.pageType = this.route.snapshot.params['pageType'];
    this.typeOfUser = this.route.snapshot.params['usertype'];
    this.idMerchant = this.route.snapshot.params['id'];
    this.profile = localStorage.getItem("profile");

    if (this.profile == "SUPERADMIN") {
      this.getResellerLevel();
    }
    //this.getResellerUsersList();

    if (this.idMerchant != null) {
      // this.getUserData(this.idMerchant);
      this.getSellerData(this.idMerchant);
      console.log('this.idMerchant != null - ID: ' + this.idMerchant)
    }
    // else {
    //   if (this.typeOfUser == "reseller")
    //     this.selectedProfileId = 2;
    //   else if(this.typeOfUser == "seller" && this.profile == "SUPERADMIN"){
    //     this.selectedProfileId = 3;
    //   }
    //   else if (this.profile != "SUPERADMIN" && this.profile != "RESPONSIBLE") {
    //     this.selectedProfileId = 3;
    //   }
    //   else if (this.profile == "RESPONSIBLE") {
    //     this.selectedProfileId = 5;
    //   }
    //   else {
    //     this.selectedProfileId = 1;
    //   }
    // }
  }

  /*getUserData(idUser) {
    this.userService.getUserDatas(idUser).subscribe((res: any) => {
      this.selectedProfileId = res.idProfile;
      this.userData = res;
    })
  }*/

  getSellerData(idSeller) {
    this.sellerService.getSellerDatas(idSeller).subscribe((res:any)=> {
      this.sellerData = res;
      console.log( 'call getSellerData' )
    })
  }

  // onChangeProfile(profile) {
  //   this.selectedProfileId = profile.id
  // }

  getLevel() {
    this.basic.getAll('/user/profile')
      .subscribe((response) => {
        if (response.results) {
          if (this.typeOfUser == "seller") {
            response.results.shift()
          }
          else if (this.typeOfUser == "reseller") {
            response.results = [response.results[1]]
          }
          for (var i = 0; i < response.results.length; i++) {
            if(this.typeOfUser != "user"){
            if (response.results[i].id == 1 || response.results[i].id == 2 || response.results[i].id == 6) {
              response.results.splice(i, 1)
            }
          }
          }
          this.profiles = response.results
          this.logService.log("Lista profili ", this.profiles, 300);
        }
      },
      (error) => {
        this.logService.log("Error", error, 200);
      }
    );
  }

  // getRateList() {
  //   this.basic.getAll('/instalment-plan/user/list')
  //     .subscribe((response) => {
  //       this.rates = response.results;
  //       this.logService.log("Rate", this.rates, 300);
  //     },
  //     (error) => {
  //       this.logService.log("Error", error, 200);
  //     }
  //   );
  // }

  getResellerLevel() {
    this.basic.getAll('/reseller/getAll')
      .subscribe((response) => {
        this.resellers = response.results;
        this.logService.log("Lista profili ", this.resellers, 300);
      },
      (error) => {
        this.logService.log("Error", error, 200);
      }
    );
  }

  onChangeResponsable(id) {
    this.userService.getResponsabileLevel(id).subscribe((res: any) => {
      this.levels = res.results;
    });
  }

  onChangeAgentResponsable(idUser) {
    this.userService.getResponsabileLevel(idUser).subscribe((res: any) => {
      this.agentLevels = res.results;
    });
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/seller');
    } else {
      this.location.back();
    }
  }

  getOnlyIdsOfArray(array) {
    let array_id = [];
    array.forEach(element => {
      array_id.push(element.idMerchant)
    });
    return array_id;
  }

  getOnlyIdInstalment(array) {
    let array_id = [];
    array.forEach(element => {
      array_id.push(element.idUser)
    });
    return array_id;
  }

  // getResellerUsersList() {
  //   this.userService.getResellerUsersList().subscribe((res: any) => {
  //     console.log("u morr merchant [manage]")
  //     this.merchantList = res.results;
  //   })
  // }
  
  private stationLabel(item: any) {
    return item.name + " " + item.surname
  }

  private useStations() {
    this.key = 'idMerchant';
    this.display = this.stationLabel;
    this.keepSorted = true;
    this.source = this.merchantList;
    this.confirmed = [];
  }
  doReset() {
    this.users = new Array<any>();
    this.useStations();
  }

  private agentLabel(item: any) {
    return item.name + " " + item.surname
  }

  doDelete() {
    if (this.source.length > 0) {
      this.source.splice(0, 1);
    }
  }

  doCreate() {
    if (typeof this.source[0] === 'object') {
      const o = {};
      o[this.key] = this.source.length + 1;
      o[this.display] = this.userAdd;
      this.source.push(o);
    } else {
      this.source.push(this.userAdd);
    }
    this.userAdd = '';
  }

  doAdd() {
    for (let i = 0, len = this.source.length; i < len; i += 1) {
      const o = this.source[i];
      const found = this.confirmed.find((e: any) => e === o);
      if (!found) {
        this.confirmed.push(o);
        break;
      }
    }
  }

  doRemove() {
    if (this.confirmed.length > 0) {
      this.confirmed.splice(0, 1);
    }
  }

  doFilter() {
    this.filter = !this.filter;
  }

  filterBtn() {
    return (this.filter ? 'Hide Filter' : 'Show Filter');
  }

  doDisable() {
    this.disabled = !this.disabled;
  }

  disableBtn() {
    return (this.disabled ? 'Enable' : 'Disabled');
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }
}
