import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { Router } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SellerService } from '../../../services/seller.service';
import { SharedService } from '../../../shared/shared.service';
import swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SellerComponent implements OnInit {
  sellerList: any = [];
  sellerDetails: any = [];
  sellerProfileList: any = [];
  sortProp: any;
  @ViewChild('dataTable') table: any;
  @ViewChild('table') tableExpandableRows: any;
  rows: any[] = [];
  expanded: any = {};
  timeout: any;
  selected = [];

  resellerList : any = [];
  arrayResellerId : any = [];
  locationPage : any;

  sortOptions = [
    { "id": 1, "name": "name", "showField":"" },
    { "id": 2, "name": "surname", "showField":"" },
    { "id": 3, "name": "state", "showField":"" },
    { "id": 4, "name": "description", "showField":"" },
    { "id": 5, "name": "idMerchant", "showField":"" }
  ]
  allStatus: any = [
    { "name": "Attivo" },
    { "name": "Bloccato" },
  ]
  msgTranslation;
  errorTranslation;
  errorMessage;
  tableRecordNumbers:number;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  searchForm: any;

  constructor(private router: Router,
    private userService:UserService,
    public appService: AppService,
    private logService: LoggingService,
    private translate: TranslateService,
    public colors: ColorsService,
    public settings: SettingsService,
    private sellerService: SellerService,
    public sharedService: SharedService, private formBuilder: UntypedFormBuilder) {
      this.tableRecordNumbers =  parseInt(localStorage.getItem('tableRecordNumbers'));
      this.translate.get('error').subscribe((res : string) => { this.errorTranslation = res; });
      this.translate.get('msg').subscribe((res : string) => { this.msgTranslation = res; });
      var self = this;
      this.translate.get('sort').subscribe(function(obj:any) {
        self.sortOptions[3].showField = obj.profile;
        self.sortOptions[4].showField = obj.date;
      });
      this.translate.get('sort').subscribe(function(obj:any) {
        self.sortOptions[0].showField = obj.name;
        self.sortOptions[1].showField = obj.surname;
        self.sortOptions[2].showField = obj.state;
      });
      // Overrides default total/empty messages
      translate.get("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );	
      translate.get("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);	
      this.locationPage = "seller"

      this.searchForm = this.formBuilder.group({
        name: [null],
        email: [null],
        status: [],
        vat: [null],
        sdi: [null]
      });

  }

  public ngOnInit(): void {
    this.getAllSellers();
    this.getAllResellers();
    
    this.sharedService.sortTableObserver.subscribe((name: any) => {
      this.sortProp = name;
      var that = this;
      setTimeout(function () {
        that.rows = [...that.rows]
      })
    })
  }

  insert() {
    this.router.navigate(['/user/seller-insert']);
  }

  getCapitals() {
    for (var i = 0; i < this.rows.length; i++) {
      var name = this.rows[i].name.split(' ')
      this.rows[i]["capitals"] = name[0].charAt(0) + name[1].charAt(0)
    }
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  async toggleExpandRowMerchant(row, expanded) {
    if (!expanded) {
      await this.getUsersDetail(row.idUser, row);
    }
    this.tableExpandableRows.rowDetail.toggleExpandRow(row);
  }

  async toggleExpandRow(row, expanded) {
    if (!expanded) {
      await this.getSellerProfile(row.idMerchant, row);
      await this.getSellerDetails(row.idMerchant, row);
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  async getUsersDetail(idUser, row) {
    const res = await this.userService.getUserDetails(idUser).toPromise();
    row['userDetails'] = res;
  }

  async getSellerProfile(idMerchant, row) {
    const res = await this.sellerService.getSellerProfile(idMerchant).toPromise();
    row['list'] = res.results;
  }

  async getSellerDetails(idMerchant, row) {
    const res = await this.sellerService.getSellerDetails(idMerchant).toPromise();
    row['sellerDetails'] = res;
  }

  onDetailToggle(event) {
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  getAllSellers(queryParams = null) {
    this.sellerService.getAllSellers(queryParams).subscribe((res: any) => {
      this.sellerList = res.results;
      this.rows = this.sellerList;
    })
  }

  getAllResellers(params = null){
    let path ="/reseller/getAll"
    if (params) {
      path += "?" + params
    }
    this.appService.getAll(path).subscribe((res:any)=>{
      this.resellerList = res.results;
      // this.rows = this.resellerList;
      if (this.resellerList) {
        for ( let i = 0, len = this.resellerList.length; i < len; i += 1) {
          var el_reseller = this.resellerList[i]
          this.arrayResellerId.push( el_reseller.idReseller )
        }
      }
    })
  }
  
  getNameReseller( id ) {
    let findResult = this.resellerList.find(x => x.idReseller === id);
    if( findResult == null || findResult == undefined )
      return '';
    return findResult.description
  }

  searchEvent(event) {
    console.log('searchEvent - event', event);
    let queryParamsSearch = this.getQueryParamsOfSearch(event)
    this.getAllSellers(queryParamsSearch)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  sortEvent(name) {
    this.sortProp = name;
    var that = this;
    setTimeout(function () {
      that.rows = [...that.rows]
    })
  }

  getQueryParamsOfSearch(searchItems) {
    let queryParamsSearch = "";
    if (searchItems.keyword)
      queryParamsSearch += "keyword=" + searchItems.keyword
    if (searchItems.searchFromSelect.length > 0) {
      if (searchItems.keyword)
        queryParamsSearch += "&"
      queryParamsSearch += "profile=" + searchItems.searchFromSelect.join()
    }
    return queryParamsSearch;
  }

  statusSelectedEvent(event, row) {
    row.statusSelectOpened = false
    row.state = event.name;
    this.changeStatus({ idUser: row.userId, state: row.state })
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }

  changeStatus(status) {
    this.userService.changeStatus(status).subscribe((response: any) => {
      if (response.outcome.success === true) {
      } else {
        swal.fire("Error", this.settings.manageErrorMsg(response.outcome), "error");
      }
    },
    (error) => {
      this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
      swal.fire("Error", this.errorMessage, "error");
    })
  }

  showTosAcceptanceLink( data ) {
    swal.fire({
      title : this.translate.instant('seller.tos_acceptance_link'),
      html: '<em title="'+ this.translate.instant('seller.tooltip_copy_tos_acceptance_link') +'">' + data + '</em>',
      icon: 'info' ,
      allowOutsideClick : false,
      allowEscapeKey : false
    })
  }

  prepareForSearch() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (Array.isArray(this.searchForm.controls[key].value)) {
          if (!!this.searchForm.controls[key].value.length) {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
          }
        } else {
          if (queryParamsSearch) {
            queryParamsSearch += "&"
          }
          queryParamsSearch += key + "=" + this.searchForm.controls[key].value
        }
      }
    }

    this.getAllSellers(queryParamsSearch)
    if(this.table) {
      this.table.offset = 0;
    }
  }

  prepareForCancel() {
    this.searchForm.reset();
    this.prepareForSearch();
  }

}

